import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { accent } from 'constants/theme';
import Button from 'components/button';
import { FormGroup, Label, Input, Message, TextArea } from './form.css';
import axios from 'axios';

class ContactForm extends React.Component {
  state = {
    isSubmitted: false,
    isError: false,
    name: '',
    email: '',
    message: '',
  };

  handleChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  };

  validateForm = () => {
    const validMessage = this.state.message.length > 0;
    const validEmail = this.state.email.includes('@');
    return validEmail && validMessage;
  };

  handleSubmit = async event => {
    event.preventDefault();

    const url = 'https://lzsyp07fuj.execute-api.us-east-1.amazonaws.com/prod/contact';
    const details = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
    };
    const headers = {
      headers: { 'Content-Type': 'application/json' },
    };

    try {
      await axios.post(url, details, headers);
      this.setState({ isSubmitted: true });
    } catch (e) {
      this.setState({ isSubmitted: true });
      this.setState({ isError: true });
    }
  };

  render() {
    const success = this.state.isSubmitted && !this.state.isError
    return (
      <form onSubmit={this.handleSubmit}>
        <FormGroup>
          <Label htmlFor="name">Name</Label>
          <Input
            id="name"
            name="name"
            type="text"
            value={this.state.name}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="email">Email</Label>
          <Input id="email" onChange={this.handleChange} />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="message">Message</Label>
          <TextArea height="70px" id="message" onChange={this.handleChange} />
          {success && (
            <Message color={accent}>Your message has been sent!</Message>
          )}
          {this.state.isError && (
            <Message color="9b3d3d">We could not send your message.</Message>
          )}
        </FormGroup>
        <Button title="Submit" type="submit" disabled={!this.validateForm()} />
      </form>
    );
  }
}

ContactForm.propTypes = {
  style: PropTypes.object,
};

export default ContactForm;
