import React from 'react';
import styled from 'styled-components';

export const FormGroup = styled.div`
	color: #111111
    display: block;
	max-width: 300px;
	margin: 40px auto;
`;

export const Label = styled.label`
	margin-bottom: 0.5em;
	color: #111111
    display: block;
`;

export const Input = styled.input`
  padding: 0.5em;
  color: #111111;
  height: ${props => props.height || 'auto'}
  background: #f8f8f8;
  border: none;
  border-radius: 3px;
  width: 100%;
  margin-bottom: 0.5em;
`;

export const TextArea = styled.textarea`
  padding: 0.5em;
  color: #111111;
  height: ${props => props.height || 'auto'}
  background: #f8f8f8;
  border: none;
  border-radius: 3px;
  width: 100%;
  margin-bottom: 0.5em;
`;

export const Message = styled.label`
	margin-bottom: 0.5em;
	color: ${props => props.color || '#c1ecbe'}
    display: block;
`;
